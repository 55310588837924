.about {
    background-color: #393e43b7;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-img {
    width: 300px; 
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    .about {
      padding: 1.5rem;
    }

    .profile-img {
      width: 250px;
    }
  }

  @media (max-width: 480px) {
    .about {
      padding: 1rem;
    }

    .profile-img {
      width: 200px;

    }
  }
