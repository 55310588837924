.background-table {
  background-color: #28282875;
  padding: 1rem;
}
 h2 {
  display:flex;
  justify-content: center;

 }

table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 2rem auto;
  width:65%; /* Adjust as needed */
  background-color: #2539488f;
  color: #ebe7ef;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  overflow: hidden; /* Ensures the border-radius applies by clipping the child elements */
}

th, td {
  padding: 12px;
  border-bottom: 2px solid #e4e4e4; /* A darker line for separation */
}

th {
  background-color: #343d48; /* Darker background for headers */
  color: #ffffff; /* Light purple as in your h2 */
  font-family: "Roboto Condensed", sans-serif;
}

td {
  font-family: "Quicksand", sans-serif;
}

tr:nth-child(even) {
  background-color: #343e4e; /* A slightly lighter purple for alternate rows */
}

tr:hover {
  background-color: #0c162d; /* A hover effect for rows */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  table {
    width: 90%;
  }
}
