@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");


body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  display: flexbox;
  align-items: center;
  justify-content: center;
  
}
@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  body {
    /* Adjust centering styles for smaller screens */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

* {
  box-sizing: border-box;
}

html {
  /* font-family: "Quicksand", sans-serif; */
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  color: #ebe7ef;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* Adjust font size for smaller screens */
    align-items: center;
    justify-content: center;

  }
}

main {
  width: 100%;
  max-width: 65rem;
  margin: auto;
}

@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  main {
    /* Adjust centering styles for smaller screens */
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  #core-concepts {
    /* Adjust centering styles for smaller screens */
    align-items: center;
    justify-content: center;
  }
}

#core-concepts {
  padding: 2rem; 
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.core-concepts-h2 {
  padding: 3rem 0 1rem 0;
  text-align: center;
  color: #000000;
  width: 100%;
}

@media (max-width: 768px) {
  .core-concepts-h2 {
    padding: 2rem 0 0rem 0;
  }
}

.group-container {
  padding: 2rem; /* Adjust padding for smaller screens */
  margin: 1rem;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: center;
  gap: 1rem;
  box-sizing: border-box;
  border-radius: 10px;
  background-color:#0000007d;
}
@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  .group-container {
    /* Adjust centering styles for smaller screens */
    justify-content: center;
  }
}
@media (max-width: 480px) {
  main {
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
  }

  .group-container {
    padding: 1rem;
    margin: 0.5rem;
  }

  #core-concepts {
    padding: 1rem;
  }
}
.core-concepts-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.core-concept-item {
  width: 45%;
  text-align: center;
  background: linear-gradient(45deg, #4c4c4c, #1a1a1a);
  padding: 0.8rem;
  border-radius: 20px;
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  position: relative;
  z-index: 0;
}

.core-concept-item img {
  height: 10rem;
  width: auto;
  object-fit: cover;
  border-radius: 1rem;
  opacity: 0.9;
}

.core-concept-item h3 {
  margin: 0.5rem 0;
}

.core-concept-item p {
  font-size: 0.9rem;
}

.core-concept-item.highlighted {
  z-index: 1; /* Ensure it's above other cards */
  transform: scale(1.2); /* Larger size */
}

.core-concept-item:not(.highlighted) {
  filter: blur(2px);
}

@media (max-width: 768px) {
  .core-concepts-list {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .core-concept-item {
    width: 100%;
    max-width: 400px;
    filter: none !important; /* Remove blur effect on mobile */
  }

  .core-concept-item.expanded {
    width: 90vw;
    max-width: 90vw;
    transform: scale(1.02);
    z-index: 2;
  }

  .core-concept-item p {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    margin: 0;
    padding: 0;
  }

  .core-concept-item.expanded p {
    max-height: 500px;
    opacity: 1;
    margin: 1rem 0;
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  .container {
    /* Adjust centering styles for smaller screens */
    justify-content: center;
  }
}