/* Datenschutz.css */

.datenschutz {
    color: #333;
    margin-bottom: 3rem
}
  
  /* Header styling */
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  header h1 {
    font-size: 2em;
    color:#030303;
  }
  
  nav ul {
    list-style-type: none;
  }
 