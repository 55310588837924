.home {
  text-align: center;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  min-height: 100vh;
}

@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  .home {
    /* Adjust centering styles for smaller screens */
    justify-content: center;
    padding: 4rem 1rem;
  }
}

.home h1 {
  margin: auto;
  font-size: 3rem; /* Adjust font size for smaller screens */
  /* Other styles */
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 5rem;
  color: #030303;
  filter: drop-shadow(0 12px 10px rgba(148, 148, 148, 0.654));
  margin-bottom: 3rem;
}

@media screen and (max-width: 768px) {
  /* Apply styles for smaller screens (e.g., phones) */
  .home h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
}  

.home p {
  margin: 0;
  font-size: 1rem; /* Adjust font size for smaller screens */
  /* Other styles */
  font-size: 1.5rem;
  color: #201f21;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
}

.home-content {
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  /* Styles for screens up to 768px wide */
  .home-content {
    /* Adjust centering styles for smaller screens */
    justify-content: center;
  }
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin: 2rem 0;
}

.JE-profile, .JE-logo {
  width: 20vw; /* Adjust width for responsiveness */
  height: auto;
  position: absolute;
  transition: transform 0.5s ease; /* Optional: For a smooth transition on hover */
}

.JE-profile {
  clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%); /* Diagonal cut */
  z-index: 2; /* Ensures this image is above the second image */
  transform: translate(-20%, -10%); /* Adjusts the position to upper left */
}

.JE-logo {
  clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%); /* Diagonal cut to complement the first image */
  transform: translate(20%, 10%); /* Adjusts the position to lower right */
}

.button-contact {
  margin-top: 3rem;
}

@media (max-width: 480px) {
  .home {
    padding: 3rem 1rem;
  }

  .home h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .home p {
    font-size: 1rem;
  }

  .image-container {
    height: 250px;
    padding: 4rem 1rem 0rem 1rem;
  }

  .JE-profile, .JE-logo {
    width: 40vw;
  }

  .JE-profile {
    transform: translate(-25%, -5%);
  }

  .JE-logo {
    transform: translate(25%, 5%);
  }

  .button-contact {
    margin-top: 2rem;
  }
}