/* CookieBanner.css */
.cookie-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  
  .cookie-banner {
    background-color: #5c5c5c9f;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .cookie-buttons button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cookie-buttons button:nth-child(1) {
    background-color: #4caf50;
    color: #fff;
  }
  
  .cookie-buttons button:nth-child(2) {
    background-color: #007bff;
    color: #fff;
  }
  