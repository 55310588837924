.navbar {
  background: linear-gradient(40deg, #000000c3, #0000007c);
  color: white;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:9%;
  position: fixed;
  width: 100%; 
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

/* Styles for the scrolled state */
.navbar.scrolled {
  height: 50px; 
  padding: 0.5rem; 
}

/* Hide navbar on scroll down (desktop only) */
@media (min-width: 769px) {
  .navbar.hide {
    transform: translateY(-100%);
  }
}

.navbar h1 {
  text-transform:uppercase;
  color:#ffffff
}

.logo {
  margin-top: 0.5rem;
  width: 90px; /* Set the width of the logo */
  transition: transform 0s ease-in-out;
}

.logo.small {
  transform: scale(0.5); /* Adjust the scale factor as needed */
  margin-top: 0.25rem;
}
.logo-img {
  max-width: 90px;
  height: auto;
}

.navbar nav ul {
    list-style: none;
    padding: 0rem;
    display: flex;
    justify-content: center;
    margin-left: auto;
}
  
.navbar nav li {
   margin: 0 1rem;
}
  
.navbar nav a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.893);
  font-weight: bold;
}

/* Mobile-only styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 0;
  }

  .navbar-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    gap: 4%;
    transition: all 0.5s ease-in-out;
  }

  .navbar-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-bottom nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 2rem;
  }

  .navbar-bottom nav a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }

  /* Hide top section when scrolled */
  .navbar.scrolled .navbar-top {
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .navbar.scrolled .navbar-bottom {
    border-top: none;
  }

  .logo {
    width: 70px;
  }

  .logo-img {
    max-width: 70px;
  }

  .navbar h1 {
    display: none; /* Hide heading */
  }

  .menu-button {
    display: block; /* Show hamburger menu */
  }
}

@media (max-width: 480px) {
  .navbar {
    font-size: 12px;
    padding: 0rem 0.25rem;
    gap: 2%;
  }

  .logo {
    width: 50px;
  }

  .logo-img {
    max-width: 50px;
  }

  .navbar nav ul {
    gap: 0.25rem;
  }

  .navbar nav li {
    margin: 0 0.25rem;
  }

  .navbar.scrolled {
    height: 40px;
    padding: 0.25rem;
  }
}


